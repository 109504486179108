<template>
  <b-modal @close="close">
    <div slot="modal-body" class="modal-wrapper-box">
      <div class="modal-container">
        <div class="modal-header">
          <span class="com-title">New User</span>
        </div>
        <div v-if="error" class="error">{{ error }}</div>
        <div class="modal-body user-form">
          <form>
            <div class="form-group">
              <label for="userName">User Name</label>
              <input
                id="userName"
                ref="usernameInput"
                v-model="userData.userName"
                type="text"
                aria-describedby="emailHelp"
              />
            </div>
            <div class="form-group">
              <label for="email">User Email <span class="required">*</span></label>
              <input
                id="email"
                ref="useremailInput"
                v-model="userData.email"
                type="text"
                @input="validateEmail"
              />
            </div>
            <div v-if="!isExternalAdmin" class="form-group lg-control-box">
              <label for="orgs">Organizations <span class="required">*</span></label>
              <k-select
                ref="organizationIdInput"
                :options="organizationOptions"
                label-key="name"
                :multiple="false"
                :value="userData.organizationId"
                :searchable="true"
                label="Please select Organization"
                :class="[isOrgAlert ? '' : 'errorBox']"
                @input="onSelectOrganization"
              />
            </div>
            <div class="form-group lg-control-box">
              <label for="role">Role <span class="required">*</span></label>
              <k-select
                :options="roleOptions"
                label-key="name"
                :multiple="false"
                :value="changedRoleId"
                :searchable="true"
                label="Please Select Role"
                :class="[isRoleAlert ? '' : 'errorBox']"
                @input="onSelectRole"
              />
            </div>
            <div v-if="isInternalAdmin" class="form-group lg-control-box">
              <label for="applications">Applications <span class="required">*</span></label>
              <k-select
                ref="userAppInput"
                name="applications"
                label-key="name"
                label="Applications"
                :multiple="true"
                :class="[isAppAlert ? '' : 'errorBox']"
                :fancy="true"
                :options="filteredAllowedApplications"
                :close-on-select="false"
                :value="userApplicationValues"
                @input="onSelectApplication"
              />
            </div>
            <div class="form-group form-check">
              <label class="form-check-label" for="inviteUser">Invite this user?</label>
              <k-checkbox
                :checked="inviteUser"
                name="inviteUser"
                @change="setInviteUser"
                @selected="setInviteUser"
              />
            </div>
            <div class="form-group" style="text-align: right; float:right; display: contents;">
              <div class="actions-btn">
                <k-button :size="3" label="SAVE" class="mr-20" @click="saveUser" />
                <k-button :size="3" label="CLOSE" @click="close" />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { get } from 'vuex-pathify';
import { isBlank } from 'adready-api/helpers/common';
import KSelect from 'adready-vue/components/elements/k-select.vue';
import usersApi, { userRolesApi } from 'adready-api/api/user';
import forklift from '~/components/mixins/forklift-mixin';
import authzMixin from '~/components/mixins/authz-mixin';

import * as CONSTANT from '~/constant';

export default {
  components: {
    BModal: () => import(/* webpackChunkName: "b-modal" */ '~/components/elements/b-modal.vue'),
    KSelect,
  },
  mixins: [forklift, authzMixin],
  props: {
    accountRef: {
      required: false,
      default: null,
      type: Object,
    },
    advertiserRef: {
      required: false,
      default: null,
      type: Object,
    },
    addUserFor: {
      required: true,
      default: () => 'advertiser',
      type: String,
    },
  },
  data() {
    const existingAccount = JSON.parse(JSON.stringify(this.accountRef));
    const existingAdvertiser = JSON.parse(JSON.stringify(this.advertiserRef));
    const filteredAllowedApplications = [];
    return {
      CONSTANT,
      loading: false,
      errorMsg: null,
      inviteUser: true,
      isRoleAlert: true,
      isAppAlert: true,
      isOrgAlert: true,
      filteredAllowedApplications,
      createdAccount: existingAccount || {},
      createdAdvertiser: existingAdvertiser || {},
      roleOptions: [],
      changedRoleId: null,
      selectedOrgType: null,
      selectedOrgId: null,
      selectedAccountOrgIds: [],
      userData: {
        provider: 'LOCAL',
      },
    };
  },
  computed: {
    allUsers: get('common/allUsers'),
    allOrganizations: get('common/allOrganizations'),
    allRoles: get('common/allRoles'),
    allApplications: get('common/allApplications'),
    currentUser: get('common/currentUser'),
    allAccounts: get('common/accounts'),
    whitelabelDomains: get('common/whitelabelDomains'),
    organizationOptions() {
      if (!this.createdAccount && !this.createdAdvertiser) {
        return this.allOrganizations;
      }

      const orgIds = [1];

      if (this.createdAccount && this.createdAccount.organizationId) {
        orgIds.push(this.createdAccount.organizationId);
      }
      if (this.addUserFor === 'advertiser') {
        if (this.createdAdvertiser && this.createdAdvertiser.organizationId) {
          orgIds.push(this.createdAdvertiser.organizationId);
        }
      }

      return this.allOrganizations.filter((a) => orgIds.some((o) => a.id === o));
    },
    applicationsForExternalEntities() {
      return this.allApplications.filter((a) => a.id === 5); // App Console
    },
    userApplicationValues() {
      if (!isBlank(this.selectedOrgType) && !isBlank(this?.userData?.userApplications)) {
        return this.allApplications.filter((a) =>
          this.userData.userApplications.some((item) => item.applicationId === a.id)
        );
      }
      return [];
    },
    localProvider() {
      return this.userData.provider === 'LOCAL';
    },
    error() {
      if (!isBlank(this.errorMsg)) {
        return this.errorMsg;
      }
      return null;
    },
  },
  watch: {
    allApplications: {
      handler(newVal) {
        this.filteredAllowedApplications = newVal.filter((a) => a.id === 5);
      },
      immediate: true, // Run immediately on component mount
    },
  },
  mounted() {
    this.loadAllUsers();
    this.loadAllOrganizations();
    this.loadRoleMasters();
    this.loadApplications();
    this.loadWhitelabelDomains();
    this.userData.accountIds = [this.accountRef.id];
  },
  methods: {
    populateRoleOptions() {
      const orgType = this.getSelectedOrgType();
      const allowedRoles =
        orgType === 'dr'
          ? ['INTERNAL_ADMIN', 'INTERNAL_SUB_ADMIN', 'INTERNAL_USER']
          : ['EXTERNAL_USER'];
      if (orgType === 'account') {
        allowedRoles.push('EXTERNAL_SUB_ADMIN');
        allowedRoles.push('EXTERNAL_ADMIN');
      }
      const { canCreateRole } = this.currentUser.role;
      return this.allRoles.filter(
        (r) => canCreateRole.includes(r.name) && allowedRoles.includes(r.name)
      );
    },
    onChangeEnable(val) {
      this.userData.enabled = val.checked;
    },
    setInviteUser(val) {
      this.inviteUser = val.checked;
    },
    onSelectRole(roleId) {
      this.changedRoleId = roleId;
      this.isRoleAlert = true;
      if (roleId === 9 || roleId === 10 || roleId === 11) {
        this.filteredAllowedApplications = this.allApplications;
      } else {
        this.filteredAllowedApplications = this.allApplications.filter((a) => a.id === 5);
        this.onSelectApplication(this.filteredAllowedApplications);
      }
      this.validateRole();
    },
    onSelectApplication(obj, performValidation = true) {
      if (!this.userData.userApplications) {
        this.userData.userApplications = [];
      }
      const data = this.userData.userApplications.filter((a) =>
        obj.some((s) => a.applicationId === s.id)
      );
      obj.forEach((o) => {
        if (!data.some((s) => s.applicationId === o.id)) {
          data.push({ applicationId: o.id });
        }
      });
      this.userData.userApplications = data;
      if (performValidation) {
        this.validateApplications();
      }
    },
    onSelectOrganization(organizationId) {
      this.selectedOrgId = organizationId;
      this.userData.organizationId = organizationId;
      const isValid = this.validateOrganizations();
      if (isValid) {
        this.roleOptions = this.populateRoleOptions();
        const roleId =
          organizationId === 1 ? CONSTANT.ROLE_ID_INTERNAL_USER : CONSTANT.ROLE_ID_EXTERNAL_USER;
        this.onSelectRole(roleId);
        this.userData.provider = organizationId === 1 ? 'GOOGLE' : 'LOCAL';
      }
      const orgObj = this.allOrganizations.filter((org) => organizationId === org.id);
      const { orgType } = orgObj[0];
      this.selectedOrgType = orgType;
    },
    async handleUser() {
      const dataValidated = this.validateData();
      if (!dataValidated) {
        return;
      }

      try {
        this.loading = true;
        if (!this.existingUser) {
          await this.addUser();
        } else {
          await this.updateUser();
        }
      } catch (err) {
        console.error('error handling user ->', err);
      } finally {
        this.loading = false;
        if (isBlank(this.errorMsg)) {
          this.close();
        }
      }
    },
    clearFormErrors() {
      this.isAlert = true;
      this.isProviderAlert = true;
      this.isRoleAlert = true;
      this.isAppAlert = true;
      this.isOrgAlert = true;
      this.errorMsg = null;

      this.$refs.useremailInput.classList.remove('errorBox');
    },
    selectDefaultApplication() {
      // Extract the Org Ids from selected Accounts (obj)
      const newSelectedAccountOrgIds = this.allAccounts
        .filter((item) => this.userData.accountIds.includes(item.id))
        .map((item) => item.organizationId);
      this.selectedAccountOrgIds = [...newSelectedAccountOrgIds];
      const allowedApplication = new Set([]);
      const flipWhitelabelOrgIds = this.whitelabelDomains
        .filter((item) => item.appType === 'FLIP')
        .map((item) => item.orgId);
      //  user has not selected any account so far.
      // if (isBlank(this.selectedAccountOrgIds)) {
      //   this.allApplications
      //     .filter((a) => a.id === 5)
      //     .forEach((app) => allowedApplication.add(app));
      // }
      if (this.selectedAccountOrgIds.some((id) => flipWhitelabelOrgIds.includes(id))) {
        // If at least one id is present in flipWhitelabelOrgIds, add app id 4
        this.allApplications
          .filter((a) => a.id === 4)
          .forEach((app) => allowedApplication.add(app));
      }

      if (this.selectedAccountOrgIds.some((id) => !flipWhitelabelOrgIds.includes(id))) {
        // If at least one id is not present in flipWhitelabelOrgIds, add app id 5
        this.allApplications
          .filter((a) => a.id === 5)
          .forEach((app) => allowedApplication.add(app));
      }
      if (allowedApplication.size === 1) {
        this.onSelectApplication(Array.from(allowedApplication), false);
      }
      if (isBlank(this.selectedAccountOrgIds)) return [];

      return Array.from(allowedApplication);
    },
    validateEmail() {
      this.clearFormErrors();
      if (isBlank(this.userData.email)) {
        this.errorMsg = 'Please enter email id';
        this.$refs.useremailInput.focus();
        this.$refs.useremailInput.classList.add('errorBox');
        return false;
      }
      if (
        !this.userData.email.match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
      ) {
        this.errorMsg = 'Please enter valid email id';
        this.$refs.useremailInput.focus();
        this.$refs.useremailInput.classList.add('errorBox');
        return false;
      }
      const userFoundWithSameEmail =
        !isBlank(this.allUsers) &&
        this.allUsers.some(
          (u) =>
            !isBlank(u.email) &&
            !isBlank(this.userData.email) &&
            u.email.toUpperCase() === this.userData.email.toUpperCase()
        );
      if (userFoundWithSameEmail) {
        this.errorMsg = `User already exists with ${this.userData.email}`;
        this.$refs.useremailInput.focus();
        this.$refs.useremailInput.classList.add('errorBox');
        return false;
      }
      if (this.isExternalAdmin) {
        this.roleOptions = this.isInternalAdmin
          ? this.allRoles.filter(
              (r) =>
                r.name === 'INTERNAL_ADMIN' ||
                r.name === 'INTERNAL_SUB_ADMIN' ||
                r.name === 'INTERNAL_USER' ||
                r.name === 'EXTERNAL_ADMIN' ||
                r.name === 'EXTERNAL_SUB_ADMIN' ||
                r.name === 'EXTERNAL_USER'
            )
          : this.allRoles.filter(
              (r) =>
                r.name === 'EXTERNAL_ADMIN' ||
                r.name === 'EXTERNAL_SUB_ADMIN' ||
                r.name === 'EXTERNAL_USER'
            );
        this.selectDefaultApplication();
      }
      return true;
    },
    validateApplications() {
      this.clearFormErrors();
      if (isBlank(this.userData.userApplications)) {
        this.errorMsg = 'Please select at least 1 application';
        this.isAppAlert = !this.isAppAlert;
        return false;
      }
      return true;
    },
    validateRole() {
      this.clearFormErrors();
      if (isBlank(this.changedRoleId)) {
        this.errorMsg = 'Please select role';
        this.isRoleAlert = !this.isRoleAlert;
        return false;
      }
      return true;
    },
    validateOrganizations(force = true) {
      if (!force) return true;
      this.clearFormErrors();
      if (isBlank(this.userData.organizationId)) {
        this.errorMsg = 'Please select an organization';
        this.isOrgAlert = !this.isOrgAlert;
        return false;
      }
      return true;
    },
    // validatePassword() {
    //   this.clearFormErrors();
    //   if (this.localProvider && !this.inviteUser && isBlank(this.userData.password)) {
    //     this.errorMsg = 'Please enter password';
    //     this.$refs.passwordInput.focus();
    //     this.$refs.passwordInput.classList.add('errorBox');
    //     return false;
    //   }
    //   return true;
    // },
    validateData() {
      this.clearFormErrors();

      if (
        !this.validateEmail() ||
        !this.validateOrganizations(this.isInternalAdmin) ||
        !this.validateRole() ||
        !this.validateApplications()
        // !this.validatePassword()
      ) {
        return false;
      }

      this.errorMsg = null;
      return true;
    },
    getSelectedOrgType() {
      let orgType = 'unknown';
      const orgId = this.userData.organizationId;
      if (orgId === 1) {
        orgType = 'dr';
      }

      if (this.createdAccount && this.createdAccount.organizationId === orgId) {
        orgType = 'account';
      } else if (this.createdAdvertiser && this.createdAdvertiser.organizationId === orgId) {
        orgType = 'advertiser';
      }

      return orgType;
    },
    async sendInvite(user) {
      try {
        await usersApi.sendInvite(user.id);
      } catch (err) {
        console.error(`error sending invite to ${user.email} (id: ${user.id}) user ->`, err);
        if (window.$sentry) {
          if (err._reported !== true) {
            window.$sentry.captureException(err);
            err._reported = true;
          }
        }
      }
    },
    async saveUser() {
      const dataValidated = this.validateData();
      if (!dataValidated) {
        return;
      }
      if (this.userData?.role?.id !== this.changedRoleId) {
        const selectedRoleOption = this.roleOptions.filter(
          (role) => this.changedRoleId === role.id
        );
        const [selectedRole] = selectedRoleOption;
        this.userData.role = selectedRole;
      }
      try {
        const user = JSON.parse(JSON.stringify(this.userData));
        if (this.inviteUser) {
          user.enabled = false;
        }
        this.loading = true;
        const createdUser = await usersApi.create(user);
        this.userData.id = createdUser.id;
        await userRolesApi.createGlobalRoles([createdUser.id, this.changedRoleId], {
          userId: createdUser.id,
          roleId: this.changedRoleId,
        });

        const orgType = this.getSelectedOrgType();

        if (orgType === 'dr') {
          // If org = dr
          if (
            this.changedRoleId === CONSTANT.ROLE_ID_INTERNAL_USER &&
            !isBlank(this.createdAdvertiser.id)
          ) {
            // and role = Internal User, add the advertiser mapping for the user
            const userAdvertiser = {
              userId: createdUser.id,
              advertiserId: this.createdAdvertiser.id,
            };
            await usersApi.mapAdvertisers(createdUser.id, [userAdvertiser]);
          } else if (this.changedRoleId === CONSTANT.ROLE_ID_INTERNAL_SUB_ADMIN) {
            // and role = Internal Sub Admin, add the account mapping for the user
            const userAccount = {
              userId: createdUser.id,
              accountId: this.createdAccount.id,
            };
            await usersApi.mapAccounts(createdUser.id, [userAccount]);
          }
        } else if (
          orgType === 'account' &&
          this.changedRoleId === CONSTANT.ROLE_ID_EXTERNAL_USER &&
          !isBlank(this.createdAdvertiser.id)
        ) {
          // If org = account && role = External user, add the advertiser mapping for the user
          const userAdvertiser = {
            userId: createdUser.id,
            advertiserId: this.createdAdvertiser.id,
          };
          await usersApi.mapAdvertisers(createdUser.id, [userAdvertiser]);
        }

        if (this.inviteUser) {
          await this.sendInvite(createdUser);
        }

        this.loadAllUsers(true);
      } catch (err) {
        this.errorMsg = 'Failed while adding the user';
        console.error('error add user api ->', err);
        if (window.$sentry) {
          if (err._reported !== true) {
            window.$sentry.captureException(err);
            err._reported = true;
          }
        }
      } finally {
        this.loading = false;
        if (isBlank(this.errorMsg)) {
          this.close();
        }
      }
    },
    close() {
      this.$emit('close', this.userData ? this.userData.id : null);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .modal-mask {
  .modal-container {
    background-color: #212429;
    min-height: 130px;
  }
  .modal-wrapper {
    margin: 0 auto;
  }
}
/**custom */
.list-box {
  position: relative;
  z-index: 2;
  color: #fff;
  background-color: #212429;
  border-radius: 0;
  padding: 30px 20px;
  width: 100%;
  display: block;
  min-width: 800px;
  max-width: 800px;
  margin: 0 auto;
  ul {
    margin-bottom: 10px;
    ul {
      border: 0;
      margin: 0;
    }
    li {
      font-size: 12px;
      line-height: 0.75rem;
      color: rgba(130, 130, 140, 0.8);
      padding-bottom: 5px;
      padding-top: 5px;
      margin-bottom: 0;
      position: relative;

      li {
        border-bottom: 1px solid rgba(170, 170, 170, 0.1);
        padding: 15px 15px 15px 10px;
        list-style: none;
        list-style-position: inside;
        font-size: 13px;
        a {
          color: #848890;
        }
        .box {
          svg {
            color: #848890;
            &:hover {
              color: var(--adminprimarycolor) !important;
            }
          }
        }
      }
      a {
        color: #cad1d6;
        display: inline;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .button-box {
    position: absolute;
    top: 5px;
    right: 0;
    line-height: 30px;
    .box {
      display: inline-block;
      margin-left: 10px;
    }
  }
}
.modal-wrapper-box {
  width: 100%;
  min-width: 500px;
  max-width: 650px;
  padding: 15px;
  margin: 0 auto;
  color: #666;
  background-color: #212429;
  font-size: 12px;
  line-height: 0.75rem;
  color: rgba(130, 130, 140, 0.8);
  .modal-header {
    margin-bottom: 10px;
    h2 {
      text-align: center;
      color: #fff;
      line-height: 1.3;
      padding-bottom: 10px;
    }
    p {
      border-top: 2px solid white;
      text-align: center;
      font-size: 18px;
      padding: 20px 0;
      margin: 0;
      color: #fff;
    }
  }
  .modal-body {
    width: 600px;
  }
  .form-group {
    margin-bottom: 15px;
    overflow: hidden;
    position: relative;
    color: #fff;
    .add-btn {
      position: absolute;
      right: 0;
      top: 0;
      color: #cad1d6;
      border: none;
      background-color: var(--adminprimarycolor);
      font-size: 16px;
      padding: 14px;
      border-radius: 0 4px 4px 0;
      line-height: 12px;
    }
    .list-url {
      clear: both;
      width: 75%;
      line-height: 25px;
      float: right;
      font-size: 13px;
      margin-top: -6px;
      min-height: 15px;
      color: #848890;
      margin-left: 120px;
      p {
        text-align: left;
      }
      a {
        display: inline;
        color: #848890;
      }
      span {
        font-size: 10px;
        top: -6px;
        color: #ff0202;
        left: 3px;
        position: relative;
      }
    }
    label {
      width: 20%;
      display: inline-block;
      font-size: 0.875rem;
      color: #cad1d6;
      letter-spacing: 0.07em;
      float: left;
      padding: 9px;
      line-height: 20px;
    }
    input {
      width: 80%;
      float: left;
      height: 30px;
      font-size: 0.875rem;
      display: block;
      min-height: 40px;
      padding: 8px 10px;
      color: #cad1d6;
      background-color: #323239;
      cursor: pointer;
      border-radius: 4px;
      border: 1px solid rgba(170, 170, 170, 0.1);
      &:hover {
        border: 1px solid var(--adminprimarycolor);
      }
    }
    select {
      width: 80%;
      float: left;
      height: 30px;
      font-size: 14px;
      color: #cad1d6;
      display: block;
      min-height: 40px;
      padding: 8px 10px;
      text-transform: capitalize;
      cursor: pointer;
      background-color: transparent;
      border: 1px solid rgba(170, 170, 170, 0.1);
      border-radius: 4px;
    }
    input[type='checkbox'] {
      width: auto;
      background-color: #323239;
      color: #cad1d6;
      &::before,
      &::after {
        background-color: #323239;
        color: #cad1d6;
      }
    }
    textarea {
      width: 80%;
      float: left;
      font-size: 0.875rem;
      display: block;
      min-height: 40px;
      padding: 8px 10px;
      color: #cad1d6;
      background-color: #323239;
      cursor: pointer;
      border-radius: 4px;
      height: 100px;
      resize: none;
      border: 1px solid rgba(170, 170, 170, 0.1);
      &:hover {
        border: 1px solid var(--adminprimarycolor);
      }
    }
    button {
      padding: 5px;
      font-size: 16px;
      margin-left: 10px;
      float: right;
    }
  }
}
.top-li {
  .button-box {
    top: 0;
  }
  a {
    svg {
      font-size: 19px;
      color: #ddd;
      &:hover {
        color: var(--adminprimarycolor) !important;
      }
    }
  }
}
.sub-li {
  padding: 10px 10px 10px 0;
  margin-top: 15px;
  // height: 30px;
  .button-box {
    top: -2px;
  }
  a {
    font-size: 14px;
    svg {
      font-size: 14px;
      color: #cad1d6;
      &:hover {
        color: var(--adminprimarycolor) !important;
      }
    }
  }
}
a {
  svg {
    color: #fff;
    &:hover {
      color: var(--adminprimarycolor) !important;
    }
  }
}
.com-title {
  display: inline-block;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.1em;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: #fff;
  margin-bottom: 20px;
}
.actions-btn {
  margin-bottom: 20px;
}
::v-deep .checkbox-btn {
  margin-left: 122px;
  height: 40px;
  span {
    font-size: 14px;
    color: #cad1d6;
    letter-spacing: 0.07em;
  }
  svg {
    width: 18px;
    height: 18px;
    &.checkbox-inactive {
      color: #cad1d6;
      cursor: pointer;
      border-radius: 4px;
      border: 1px solid rgba(170, 170, 170, 0.1);
      background-color: #323239;
      path {
        fill: #323239;
      }
    }
  }
}
.mr-20 {
  margin-right: 20px;
}
::v-deep .k-btn {
  display: inline-block;
  min-width: 75px;
  font-weight: 600;
  color: #303335 !important;
  text-transform: uppercase;
  letter-spacing: 0.07em;
  background-color: var(--adminprimarycolor);
  border-radius: 3px;
  appearance: none;
}
::v-deep .k-btn.btn-size-3 span.label {
  padding: 9px 19px;
  line-height: 20px;
  color: var(--adminovertext);
}
::v-deep .k-btn:hover {
  color: #cad1d6 !important;
  background-color: #424242 !important;
}
.line-hr {
}
.sm {
  font-size: 13px;
  font-weight: 600;
  font-style: italic;
  border-top: 1px solid var(--adminprimarycolor);
  padding: 10px 0 20px 0;
  width: 100%;
  text-align: left;
  color: #fff;
  margin-top: -20px;
}
tr.no-bg {
  line-height: 80px;
  &:hover {
    background-color: transparent !important;
  }
  input {
    width: 95% !important;
  }
  td {
    text-align: right;
    padding: 0 36px 0 8px;
  }
}
.mg-tp-20 {
  margin-top: 20px;
}
tr.no-bg-no-pg {
  line-height: 40px;
  &:hover {
    background-color: transparent !important;
  }
  input {
    width: 95% !important;
  }
  td {
    text-align: right;
    padding: 0 0 0 8px;
  }
}
td a {
  display: inline-block;
  margin-right: 8px;
}
.col-lh-40 {
  line-height: 40px;
  display: inline;
}
tr.no-bg-axnbtn {
  overflow: hidden;
  &:hover {
    background-color: transparent !important;
  }
  input {
    width: 95% !important;
  }
  td {
    text-align: right;
    padding: 0 36px 0 8px;
  }
}

.lg-control-box {
  display: inline-flex;
  width: 100%;
  label {
    width: 25% !important;
  }
}
::v-deep .form-input-wrap {
  height: 42px;
  text-transform: capitalize !important;
  cursor: pointer !important;
  background-color: #323239 !important;
  border: 1px solid rgba(170, 170, 170, 0.1) !important;
  border-radius: 4px !important;
  color: #cad1d6 !important;
  font-size: 14px !important;
  .form-click {
    border: none !important;
  }
}

::v-deep .modal-body .form-input .form-input-field.v-select {
  text-transform: capitalize !important;
  cursor: pointer !important;
  background-color: #323239 !important;
  border: 1px solid rgba(170, 170, 170, 0.1) !important;
  border-radius: 4px !important;
  color: #cad1d6 !important;
  font-size: 14px !important;
}
::v-deep .form-input .form-input-field.v-select .vs__dropdown-toggle {
  background-color: #323239 !important;
  min-height: 38px !important;
}
::v-deep .form-input .form-input-field.v-select .vs__search {
  padding: 4px 6px;
  color: #cad1d6;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
::v-deep
  .form-input
  .form-input-field.v-select
  .vs__dropdown-toggle
  .vs__actions
  .action-icons
  svg {
  color: #cad1d6;
  font-size: 14px;
}
::v-deep
  .form-input
  .form-input-field.v-select
  .vs__dropdown-toggle
  .vs__actions
  .row.action-icons {
  margin-left: -0.9rem !important;
}
::v-deep .form-input-wrap .form-click .action-icons svg,
::v-deep .form-input-wrap .form-click .selected-text {
  font-size: 14px;
}
::v-deep
  .form-input
  .form-input-field.v-select.vs--open
  .vs__dropdown-toggle
  .vs__actions
  .action-icons
  svg {
  transform: rotate(-180deg);
  font-size: 14px;
}

::v-deep .dropdown .dropdown-menu {
  position: initial !important;
}
::v-deep .modal-body .form-click {
  // border: 1px solid rgba(170, 170, 170, 0.1) !important;
  padding: 0;
  margin: 0;
  &.disabled {
    cursor: not-allowed;
  }
}
::v-deep .form-input .form-input-field.v-select .vs__selected-options {
  padding: 0 6px !important;
}
::v-deep .form-input .form-input-field.v-select .vs__dropdown-toggle .vs__selected {
  padding: 0 6px !important;
  margin: 0 4px !important;
}
.vs__selected {
  color: #01abb7 !important;
  background-color: rgba(78, 78, 78, 0.4) !important;
}
::v-deep .form-input-wrap .form-click .selected-text {
  padding: 0 0 0 14px !important;
}

::v-deep .form-input-wrap .form-click .action-icons {
  padding: 0 0 0 0 !important;
}

::v-deep .form-input .form-input-field.v-select .vs__dropdown-toggle .vs__actions {
  padding: 2px 15px 0 0 !important;
}
::v-deep .form-group.dd-options {
  overflow: unset !important;
  .dropdown {
    .dropdown-menu {
      position: absolute !important;
    }
  }
}
::v-deep .user-form .dropdown {
  width: 80%;
  margin-right: 0 !important;
}

::v-deep .form-input-wrap .form-click .action-icons svg {
  position: relative;
  left: -8px;
}
</style>
